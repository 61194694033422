var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{staticClass:"overflow-hidden"},[_c('div',{attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"p-6",class:{
        'flex justify-between flex-row-reverse items-center': _vm.iconRight,
        'text-center': !_vm.iconRight && _vm.hideChart,
        'pb-0': !_vm.hideChart,
      }},[_c('feather-icon',{staticClass:"p-3 inline-flex rounded-full",class:[`text-${_vm.color}`, { 'mb-4': !_vm.iconRight }],style:({ background: `rgba(var(--vs-${_vm.color}),.15)` }),attrs:{"icon":_vm.icon}}),_c('div',{staticClass:"truncate"},[_c('h3',{staticClass:"mb-1 font-bold"},[_c('Roller',{class:{
              'text-white': this.isThemedark === 'dark',
            },attrs:{"is-number-format":true,"transition":1.2,"text":`${_vm.statistic.toString()} `}})],1),_c('span',[_vm._v(_vm._s(_vm.statisticTitle))])])],1),(!_vm.hideChart)?_c('div',{staticClass:"line-area-chart"},[_c('vue-apex-charts',{ref:"apexChart",attrs:{"type":_vm.type,"options":_vm.chartOptions,"series":_vm.chartData,"height":"100","width":"100%"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }